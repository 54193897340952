<template>
  <div class="login">
    <img
      class="bg-img"
      src="https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/backimg.png"
      alt="" />
    <div class="showbox">
      <img
        class="left-img"
        src="https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/leftlogo.png"
        alt="" />
      <div class="login_box">
        <h2>登录教师端</h2>
        <el-form
          label-position="top"
          ref="form"
          :rules="rules"
          :hide-required-asterisk="true"
          label-width="80px"
          :model="form">
          <el-form-item label="账号" prop="username">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="form.password" show-password></el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="form.checked">记住密码</el-checkbox>
            <!-- <span class="wanjimm"  >忘记密码?</span> -->
          </el-form-item>
          <el-form-item>
            <el-button type="primary" round @click="login">立即登录</el-button>
          </el-form-item>
        </el-form>
        <!-- <div class="tip flexSt">
          <div>
            <div
              @click="
                handleOpen('https://beian.miit.gov.cn/#/Integrated/index')
              ">
              Copyright © 2024 sishengsports.com All Rights Reserved. 
              湘ICP备2022018338号-1
            </div>
            <div
              @click="
                handleOpen(
                  'https://beian.mps.gov.cn/#/query/webSearch?code=43019002002110'
                )
              "
              class="flexSc">
              <div class="flexSc">
                <img class="aaaaaa" src="../assets/219.png" alt="" />
                湘公网安备43019002002110号
              </div>

              <a href="mailto:kefu@sishengsports.com">投诉举报</a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
        checked: false,
      },
      rules: {
        username: [
          // required: 非空规则
          // message: 提示信息
          // trigger: 触发的方式  blur  change
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern:
              /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F])[\da-zA-Z\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]{8,}$/,
            message: "密码至少为8位,包含数字、大小写字母以及特殊字符",
            trigger: "blur",
          },
          // {
          //   min: 6,
          //   max: 12,
          //   message: "长度在 6 到 12 个字符",
          //   trigger: "change"
          // }
        ],
      },
      color: "",
      bgstyle: "",
    };
  },
  created() {
    this.color = localStorage.getItem("color");
    this.bgstyle = JSON.parse(localStorage.getItem("bgstyle"));
  },
  methods: {
    handleOpen(url) {
      window.open(url);
    },
    async login() {
      try {
        await this.$refs.form.validate();
        // const res = await this.axios.post("/auth/login", {/auth/web/login
        const res = await this.axios.post("/auth/web/login", {
          row: {
            username: this.form.username,
            password: this.form.password,
          },
        });
        if (res.code === 1) {
          const { data } = res;
          localStorage.setItem("token", data.token);
          localStorage.setItem("user", JSON.stringify(data.user));
          if (!this.color || !this.bgstyle) {
            localStorage.setItem("color", "#2e398b");
            localStorage.setItem(
              "bgstyle",
              JSON.stringify({ background: "#2e398b" })
            );
          }
          this.$router.push({ name: "index" });
        } else {
          this.$message.error(res.msg);
        }
      } catch (e) {
        return false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 28px;
  color: #999999;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #999999;
}
/deep/ .el-checkbox__inner {
}
/deep/ .el-form-item__label {
  color: #999999;
  font-size: 30px;
}
/deep/ .el-input__inner {
  height: 80px;
  background: #ffffff;
  border-radius: 18px 18px 18px 18px;
  border: 1px solid #4a80f7;
}
/deep/ .input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: #ffffff !important;
  color: fieldtext !important;
}
.login {
  width: 100%;
  height: 100vh;
  .bg-img {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .showbox {
    width: 90%;
    // height: 750px;
    background: #ffffff;
    margin: auto;
    margin-top: 5%;
    box-shadow: 6px 6px 30px 1px #0050ff;
    border-radius: 30px 30px 30px 30px;
    display: flex;
    .left-img {
      width: 51.6%;
      height: 100%;
    }
  }
  .login_box {
    height: 100%;
    width: 44.79%;
    flex-direction: column;
    align-items: center;
    .wanjimm {
      font-size: 40px;
      color: #4a80f7;
    }
    h2 {
      text-align: center;
      font-size: 40px;
      color: #4a80f7;
      margin-top: 12%;
    }
    .el-form {
      width: 100%;
      margin-top: 4%;
      span {
        float: right;
        cursor: pointer;
        font-size: 15px;
        color: #537af2;
      }
      :deep(.el-button) {
        width: 100%;
        border-radius: 50px;
        font-size: 30px;
        background: #537af2;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        height: 80px;
        margin-top: 5%;
      }
    }
  }

  .tip {
    margin-top: 30px;
    font-size: 17px;
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    color: #b2b2b2;
    text-align: center;
    cursor: pointer;
    a {
      color: #b2b2b2;
      margin-left: 10px;
    }
    a:hover {
      color: #3c7dff;
    }
    .aaaaaa {
      width: 18px;
      height: 20px;
      margin-right: 10px;
    }
  }
}
</style>
